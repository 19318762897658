/*-------- APP.JS --------*/

:root {
    --page-title-height: 5vh;
    --customers-filters-height: 8vh;
    --customers-page-navigation: 8vh;
}

::selection {
    color: white !important;
    background: #0d8a72 !important;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

p {
    margin-top: 0;
    margin-bottom: 0;
}

#root {
    min-width: 100%;
    min-height: 100%;
}

/*to hide process not defined overlay until not fixed by react-scripts update.
check issue status once in a while here: https://github.com/facebook/create-react-app/issues/11773 */

body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) { display: none; }

body {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
    background: #f2f4f5 !important;
    font-size: 14px !important;
}

h1 {
    font-size: 28px !important;
}

h2 {
    font-size: 21px !important;
}

.input-field:focus,
.input-user-page:focus,
.input-user-page:hover {
    border-color: #0d8a72 !important;
}

/*geosuggest*/

.geosuggest__item {
    list-style-type: none;
    padding: 0.8rem 1.6rem 0.8rem 1.6rem;
    margin-left: 0;
}

.geosuggest__item:hover,
.geosuggest__item:focus {
    background: rgb(163, 162, 162) !important;
}

.geosuggest__item--active {
    background: #267dc0 !important;
    color: #fff;
}

.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
}

ul.geosuggest__suggests {
    width: 100%;
}

:root {
    --main-color: #111;
    --loader-color: #4caf50;
    --back-color: #a5d6a7;
    --time: 3s;
    --size: 3px;
}

.loader__element {
    height: var(--size);
    width: 100%;
    background: var(--back-color);
}

.loader__element:before {
    content: "";
    display: block;
    background-color: var(--loader-color);
    height: var(--size);
    width: 0;
    animation: getWidth var(--time) ease-in infinite;
}

@keyframes getWidth {
    100% {
        width: 100%;
    }
}

.render-documents {
    max-height: 350px !important;
    overflow-y: scroll !important;
    width: 100% !important;
}

.project-logo-menu {
    width: 100%;
    cursor: auto;
    margin: 0.5rem auto;
    padding: 4px 1rem;
}

.map-btn-right {
    text-align: right !important;
    position: absolute !important;
    right: 1rem !important;
    top: 0.5rem !important;
    z-index: 1000 !important;
}

.balance-table-dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    cursor: pointer;
}

.project-logo {
    text-align: center;
    width: 180px;
    height: 180px;
    margin-left: auto;
    margin-right: auto;
}

.modal.in {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.card-content {
    padding: 0.5rem 1rem;
    height: 100%;
}

.input-field {
    width: 100%;
    border: 1px solid hsl(0, 0%, 80%) !important;
    border-radius: 5px;
    padding: 0.5rem;
    transition: 0.3s;
    height: 40px;
    -webkit-appearance: none;
    -moz-appearance: auto;
}

.error-input-field,
input:invalid {
    border: 1px solid #ff6347;
    transition: 0.3s;
    filter: drop-shadow(0 0 0.2rem rgba(255, 99, 71, 0.3));
}

.css-1uccc91-singleValue {
    display: -webkit-box;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*-------- MENU --------*/

.user-icon {
    position: relative;
    bottom: 3rem;
}

.like-link {
    cursor: pointer;
    transition: 0.3s;
    text-decoration: none;
    color: black;
}

.like-link:hover,
.like-link-selected {
    color: orange !important;
    transition: 0.3s;
}

.submenu-list-item > a:hover {
    color: orange !important;
    transition: 0.3s;
}

.container {
    max-width: 100% !important;
}

.customer-card-container {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    background-color: white;
    filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.1));
    justify-content: space-between;
    margin-bottom: 1rem;
}

.survey-status {
    float: right;
    text-align: right;
    padding-right: 1.5rem;
    padding-top: 1.5rem;
}

.survey-app {
    margin-top: 10px;
    height: 75vh;
    overflow-y: scroll;
}

.survey-table {
    width: 80% !important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.split-item-add-customer:first-child {
    padding-right: 0.5rem;
}

/*-------- DASHBOARD --------*/

@-webkit-keyframes fadein-custom {
    0% {
        opacity: 0;
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.05, 1.05);
    }
    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
}

@keyframes fadein-custom {
    0% {
        opacity: 0;
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.05, 1.05);
    }
    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
}

@-webkit-keyframes fadeout-custom {
    0% {
        opacity: 1;
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.05, 1.05);
    }
    100% {
        opacity: 0;
        transform: scale(0, 0);
    }
}

@keyframes fadeout-custom {
    0% {
        opacity: 1;
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.05, 1.05);
    }
    100% {
        opacity: 0;
        transform: scale(0, 0);
    }
}

.smaller-select {
    width: 100%;
}

/*-------- CHARTS --------*/

.probability-default-container {
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
    border: 1px solid #e5e5e5;
    justify-content: center;
}

.rating-data-row {
    margin: 0.5rem;
    display: flex;
    flex-direction: row;
}

.row-rating-data {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.rating-data-cell {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.finantial-ratios-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

/*****content value wizard*********/

.calc_modal {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 15px;
    margin-bottom: 15px;
}

.main-sectors {
    height: 250px;
    overflow-y: scroll;
    width: 100%;
    border: 1px #e5e5e5 solid;
    padding: 0.5rem 1rem;
    border-radius: 4px;
}

.second-table-option {
    width: 239px;
}

@media (max-device-width: 1366px) and (orientation: landscape) {

    .heatmap-main {
        margin-left: 0px !important;
    }

}

.container {
    position: relative;
}

.container img {
    display: block;
}

/*-------- LOGIN --------*/

.login-container {
    height: 100vh;
    width: 100vw;
    background: linear-gradient(45deg, #7794c3, #0d8a72);
    max-width: 100% !important;
}

@-webkit-keyframes LoginContainerAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@-moz-keyframes LoginContainerAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes LoginContainerAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.login-form-container {
    width: 450px;
    height: 500px;
    background-color: #fff;
    border-radius: 5px;
    padding: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-support {
    margin-top: 15px !important;
}

/*-------- USER PAGE --------*/

.input-user-page {
    margin-top: 0.2rem;
    margin-bottom: 1rem;
}

/*CONSTRUCTION PAGE*/

@-webkit-keyframes fadein-map-custom {
    0% {
        width: 100%;
    }
    100% {
        width: 50%;
        padding-right: 0.5rem;
    }
}

@keyframes fadein-map-custom {
    0% {
        width: 100%;
    }
    100% {
        width: 100%;
        padding-right: 0.5rem;
    }
}

@-webkit-keyframes fadeout-map-custom {
    0% {
        width: 50%;
        padding-right: 0.5rem;
        border-right: 1px solid #e5e5e5;
        border-width: 0 1px 0 0;
    }
    100% {
        border: none;
        border-width: 0;
        width: 100%;
        margin-right: 0;
    }
}

@keyframes fadeout-map-custom {
    0% {
        width: 50%;
        padding-right: 0.5rem;
        border-right: 1px solid #e5e5e5;
        border-width: 0 1px 0 0;
    }
    100% {
        border: none;
        border-width: 0;
        width: 100%;
        margin-right: 0;
    }
}

.input-field-map-form {
    margin: 0.5rem;
    margin-top: 1px;
}

.formrow > label {
    width: 40%;
}

.formrow > input,
.formrow > select {
    width: 100%;
}

.weather-formrow > label {
    width: 100%;
}

.weather-formrow > input,
.weather-formrow > select {
    margin: 0px;
    margin-bottom: 10px;
    width: 100%;
}

.empty-weather {
    text-align: center;
    margin: 1rem;
}

.assets-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 390px) {
    .survey-status {
        text-align: left;
        padding-left: 74px;
    }

    #container {
        height: 400px;
    }

    .highcharts-data-table table {
        font-family: Verdana, sans-serif;
        border-collapse: collapse;
        border: 1px solid #ebebeb;
        margin: 10px auto;
        text-align: center;
        width: 100%;
        max-width: 500px;
    }

    .highcharts-data-table caption {
        padding: 1em 0;
        font-size: 1.2em;
        color: #555;
    }

    .highcharts-data-table th {
        font-weight: 600;
        padding: 0.5em;
    }

    .highcharts-data-table td,
    .highcharts-data-table th,
    .highcharts-data-table caption {
        padding: 0.5em;
    }

    .highcharts-data-table thead tr,
    .highcharts-data-table tr:nth-child(even) {
        background: #f8f8f8;
    }

    .highcharts-data-table tr:hover {
        background: #f1f7ff;
    }

    .main-sectors {
        height: 150px;
        overflow-y: scroll;
        width: 100%;
        border: 1px #e5e5e5 solid;
        border-radius: 4px;
        line-height: 15px;
    }

    .first-input {
        margin: 0px;
    }

    .card-name {
        margin-top: 35px !important;
    }

    .heatmap-main {
        margin-left: 0px !important;
    }

    .page-title {
        width: 99% !important;
    }

}

.customContent {
    overflow-x: hidden;
    padding: 1rem;
}

#custom-header {
    padding: 0.5rem 1rem 0 1rem;
    background-color: #fff;
    height: auto;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 5;
}

.customer-card-container {
    padding: 1rem;
}


.custom-link {
    cursor: pointer;
    color: #2697ff;
    margin-bottom: 10px;
    text-decoration: none;
}

.primary-btn, .primary-btn:active {
    background-color: #0d8a72;
    color: white;
    border: 1px solid #0c7761;
}

.primary-btn:focus {
    background-color: #0c7c66;
    color: white;
    border: 1px solid #0c725d;
}

.primary-btn:hover {
    background-color: white;
    color: #0d8a72;
    border: 1px solid #0d8a72;
}

.select-input {
    background: url("data:image/svg+xml,<svg height='20px' width='20px' viewBox='0 0 20 20' fill='%23000000' fill-opacity='0.3' xmlns='http://www.w3.org/2000/svg'><path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
}

input:disabled {
    color: #000 !important;
}

div#gallery-card > div > div > div > div {
    flex-grow: 1;
    margin: 0.5rem;
}

@media screen and (max-width: 576px) {
    .customContent {
        width: 100vw;
    }

    #custom-header {
        width: 100vw;
    }

    .customer-card-container {
        padding: 1rem 0.5rem;
    }

}

.highcharts-navigator-mask-inside {
    fill: rgba(182, 213, 182, 0.4);
}

.highcharts-range-selector-buttons {
    display: none;
}