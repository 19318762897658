a {
    color: unset;
}

.ant-collapse-content-active {
    border-top: 1px solid #e5e5e5 !important;
}

h1, h2, h3, h4, h5, h5 {
    margin-bottom: 0;
}

.ant-picker-panel-container {
    border-radius: 5px;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: #0c7761;

}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #0c725d;
}

.anticon {
    vertical-align: middle !important;
}

/*Buttons*/
.ant-btn {
    border-radius: 5px;
    text-shadow: none !important;
}

.ant-btn-dangerous, .ant-btn-dangerous:active {
    background-color: white !important;
    color: red !important;
    border-color: red !important;
}

.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
    background-color: #ce0000 !important;
    color: white !important;
    border-color: darkred !important;
}

/*Input*/
.ant-input, .ant-input-affix-wrapper {
    border-radius: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
}

.ant-input:focus,
.ant-input::selection,
.ant-input:hover,
.input-field:focus,
.input-field:hover,
.input-field::selection,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper::selection,
.ant-input-affix-wrapper:hover{
    outline: 2px solid rgba(13, 138, 114, 0.2);
    outline-offset: 0px;
    border: 1px solid #0d8a72 !important;
    box-shadow: none;
}

.password-input-field > input.ant-input {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    height: 28px;
}

.ant-switch-checked {
    background-color: #0d8a72;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0d8a72;
    border-color: #0d8a72;
}

.ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #0c725d;
}

/*Table*/

.ant-table-tbody tr:nth-child(2n) td {
    background-color: white !important;
}

.ant-table-tbody tr:nth-child(2n+1) td {
    background-color: #f2f4f5 !important;
}

.ant-table-thead > tr > th {
    border-bottom: 1px black solid !important;
}

.ant-table-tbody tr:hover td {
    background-color: #e0e2e3 !important;
}

.ant-table-cell {
    text-align: center !important;
    vertical-align: middle;
}

#gross-profit-table .ant-table-cell {
    text-align: left !important;
    vertical-align: middle;
}

/*Pagination*/

.ant-pagination-item-active > a:hover,
.ant-pagination-item-active > a,
.ant-pagination-item-active,
.ant-pagination-item-active:hover,
.ant-pagination-item:hover,
.ant-pagination-item > a:hover,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
    border-color: #0d8a72;
    color: #0d8a72;
}

/*Steps*/

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #198754;
}

.ant-steps-item-process .ant-steps-item-icon {
    border-color: #198754;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #198754;
}

.ant-steps-item-finish .ant-steps-item-icon {
    border-color: #198754;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after, .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #198754;
}

/*Tabs*/

.ant-tabs-ink-bar {
    background: #0c7761;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active,
.ant-tabs-tab:hover {
    color: #0c7761;
}

/*Collapse*/

.ant-collapse,
.ant-collapse-borderless {
    background-color: transparent;
}

.ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0 0 5px 5px;
}

.ant-collapse.assetReview > .ant-collapse-item {
    border: none;
}

/*Form*/
.ant-form-item {
    margin-bottom: 1rem !important;
}

.ant-form-item-label {
    padding-bottom: 0 !important;
}

/*Layout*/
.ant-page-header-ghost {
    background-color: white !important;
    box-shadow: 0px 5px 5px -7px rgba(0, 0, 0, 0.5);
    z-index: 100;
}

section > aside.ant-layout-sider-collapsed > div.ant-layout-sider-children > ul > li.ant-menu-item:nth-child(2) {
    padding-left: calc(50% - 30px / 2);
}

.ant-layout-sider-trigger {
    color: #000;
    background: #96C394;
}

.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
    width: 100%;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
    padding: 0 calc(50% - 25px / 2);
}

.ant-menu > .ant-menu-item {
    padding: 0 25px;
    color: #09483a;
}

.ant-menu-item-selected a {
    color: #09483a;
}

.ant-menu.ant-menu-inline-collapsed.standardItems > .ant-menu-item:first-child {
    padding: 0 calc(50% - 36px / 2);
}

.ant-menu.standardItems > .ant-menu-item:first-child {
    padding: 0 20px;
}

.ant-menu-item-selected, .ant-menu-item-selected, .ant-menu-item-active.ant-menu-item-selected {
    color: #000000;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
}

/*Card*/

.ant-card-actions {
    background-color: transparent;
}

/*List*/

.ant-list-grid .ant-col > .ant-list-item {
    display: flex;
    justify-content: center;
}

/*Empty*/

.ant-empty-description {
    color: rgba(0, 0, 0, 0.25);
}

/*Error boundary*/
#root > section > div.ant-alert {
    background-color: #FFFFFF;
    border: none;
    height: 100vh;
}

#root > section > div.ant-alert > div.ant-alert-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/*Avatar*/
.ant-avatar > img {
    object-fit: contain;
    background-color: #fafafa;
}

/*Image*/
.ant-image-mask:hover {
    opacity: 1;
    border-radius: 4px;
}
.ant-image-mask {
    border-radius: 4px;
}